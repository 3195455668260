// comment to trigger build
/* eslint-disable camelcase */
import React from 'react'
import PropTypes from 'prop-types'
import { Layout } from '@leshen/gatsby-theme-contentful'
import styled from '@emotion/styled'

import {
  Billboard,
  VariableContent,
  Image,
  List,
  ListItem,
  LeshenPhoneCTA,
  PackageCard,
  Brandy,
  Price,
} from '@leshen/gatsby-theme-leshen'

import { Dropdown, Columns, Typography } from '@leshen/ui'
import { graphql, navigate, Link as GatsbyLink } from 'gatsby'

import getFullStateName from '../utils/getFullStateName'

const StateFiber = ({ data }) => {
  const { Meta_Description, State, Promo_Code, Page_Path, Meta_Title } =
    data.pageInfo.edges[0].node

  const State_Name = getFullStateName(State)

  const cityDropdownData = data.cities.edges
    .map((current) => ({
      label: current.node.City,
      value: current.node.Page_Path,
    }))
    .sort((a, b) => a.label.localeCompare(b.label))

  const passedData = {
    ...data,
    contentfulPage: {
      seo: {
        canonical: '',
        description: Meta_Description,
        robots: 'index,follow',
        title: Meta_Title,
        titleTemplate: '',
      },
      path: Page_Path,
      promoCode: Promo_Code,
      customBreadCrumbs: null,
      header: data.allContentfulHeader.edges[0].node,
      footer: data.allContentfulFooter.edges[0].node,
    },
  }

  return (
    <Layout
      data={passedData}
      main={
        <>
          <Billboard
            customLink={GatsbyLink}
            backgroundColor="dark"
            imageToBottom
            image={
              <Image
                data={data.couchComputerImage.cloudinary[0].gatsbyImageData}
                isImageCritical
              />
            }
            mobileImage={
              <Image
                data={
                  data.couchComputerImageMobile.cloudinary[0].gatsbyImageData
                }
                isImageCritical
              />
            }
            mainContent={
              <>
                <Typography variant="h1">
                  Get <br />
                  Frontier&reg; Fiber Internet
                </Typography>
                <Typography variant="h4">in {State_Name}</Typography>
                <Price
                  standalone
                  className="price embedded-entry"
                  {...data.heroPrice?.price}
                  bullets={data?.heroPrice?.bullets}
                  variant="Default"
                />
                <List>
                  <ListItem>
                    <Typography>
                      Amazon eero Wi-Fi Pro 6 router included<sup>**</sup>
                    </Typography>
                  </ListItem>
                  <ListItem>
                    <Typography>No data caps or overage charges</Typography>
                  </ListItem>
                  <ListItem>
                    <Typography>
                      Extreme bandwidth for smart homes with dozens of devices
                    </Typography>
                  </ListItem>
                </List>
                <br />
                <br />
                <LeshenPhoneCTA variant="hero" color="primary">
                  Call
                </LeshenPhoneCTA>
              </>
            }
          />
          <VariableContent
            mainContent={
              <>
                <Typography variant="h2">
                  Fiber Internet from Frontier: Reliable speeds for all your
                  needs
                </Typography>
                <Typography variant="">
                  All Frontier Fiber Internet plans include a FREE premium Wi-Fi
                  router, NO data caps, and internet speeds that can power all
                  your devices.
                </Typography>
              </>
            }
            alignMainContent="Center"
            alignImageToBottom={false}
            centeredContent={false}
            backgroundColor={null}
            customLink={null}
          >
            <Columns
              className=""
              leftAligned={false}
              cardStyled={false}
              mobileCarousel={false}
              extraTopSpacingForLabel={false}
            >
              <PackageCard
                label={false}
                packageData={data.package1}
                content={<LeshenPhoneCTA color="primary">Call</LeshenPhoneCTA>}
              />

              <PackageCard
                label={false}
                packageData={data.package2}
                content={<LeshenPhoneCTA color="primary">Call</LeshenPhoneCTA>}
              />

              <PackageCard
                label={false}
                packageData={data.package3}
                content={<LeshenPhoneCTA color="primary">Call</LeshenPhoneCTA>}
              />

              <PackageCard
                label={false}
                packageData={data.package4}
                content={<LeshenPhoneCTA color="primary">Call</LeshenPhoneCTA>}
              />
            </Columns>
          </VariableContent>
          <VariableContent
            backgroundColor="dark"
            mainContent={
              <>
                <Typography variant="h2">
                  Extend your Wi-Fi signal starting at $10/mo<sup>♦</sup>
                </Typography>
                <Typography>
                  Eliminate dead spots by adding Whole-Home Wi-Fi extenders to
                  your plan.
                </Typography>
                <LeshenPhoneCTA color="primary">Call</LeshenPhoneCTA>
              </>
            }
            alignMainContent="Left"
          />
          <VariableContent
            backgroundColor="primary"
            alignMainContent="Center"
            mainContent={
              <>
                <Typography variant="h2">Check availability by city</Typography>
                <Typography variant="h6">
                  Find the best Internet deals in your area:
                </Typography>
                <Typography variant="">
                  Please choose your city from the list
                </Typography>
                <Dropdown
                  options={cityDropdownData}
                  onChange={(e) => navigate(e.value)}
                  placeholderText="Select City"
                />
                {cityDropdownData?.map((current) => (
                  <StyledLink href={current.value} key={current.value}>
                    {current.label}
                  </StyledLink>
                ))}
              </>
            }
          />

          <VariableContent
            backgroundColor=""
            mainContent={
              <>
                <Brandy
                  symbol={data.disclaimerOne.symbol}
                  text={data.disclaimerOne.text}
                  variant="legal"
                />
                <Brandy
                  symbol={data.disclaimerTwo.symbol}
                  text={data.disclaimerTwo.text}
                  variant="legal"
                />
                <Brandy
                  symbol={data.disclaimerThree.symbol}
                  text={data.disclaimerThree.text}
                  variant="legal"
                />
                <Brandy
                  symbol={data.disclaimerFour.symbol}
                  text={data.disclaimerFour.text}
                  variant="legal"
                />
                <Brandy
                  symbol={data.disclaimerFive.symbol}
                  text={data.disclaimerFive.text}
                  variant="legal"
                />
                <Brandy
                  symbol={data.disclaimerSix.symbol}
                  text={data.disclaimerSix.text}
                  variant="legal"
                />
                <Brandy
                  symbol={data.disclaimerSeven.symbol}
                  text={data.disclaimerSeven.text}
                  variant="legal"
                />
                <Brandy
                  symbol={data.disclaimerEight.symbol}
                  text={data.disclaimerEight.text}
                  variant="legal"
                />
              </>
            }
            alignMainContent="Left"
          />
        </>
      }
    />
  )
}

StateFiber.propTypes = {
  data: PropTypes.shape({}).isRequired,
}

export default StateFiber

const StyledLink = styled.a`
  display: none !important;
`

export const query = graphql`
  query StateFiberQuery($pagePath: String, $stateAbbreviation: String) {
    site {
      ...LeshenMetadata
    }
    pageInfo: allDatasetManagerFrontierinternetserviceAoa(
      filter: { Page_Path: { eq: $pagePath } }
    ) {
      edges {
        node {
          State
          Promo_Code
          Page_Path
          Meta_Title
          Meta_Description
        }
      }
    }
    allContentfulHeader(
      filter: { id: { eq: "cc1a2964-2e39-57e2-8248-f162d9d02e51" } }
    ) {
      edges {
        node {
          id
          __typename
          identifier
          navigationPosition
          hideLocation
          navigationPosition
          headerBackgroundColor
          headerTextColor
          topBarBackgroundColor
          topBarTextColor
          navigationBackgroundColor
          navigationTextColor
          hoverBackgroundColor
          hoverTextColor
          logoLink
          borderColor
          borderHeight
          textBlockAlignment
          textBlock {
            raw
          }
          linkGroups {
            ...LeshenLinkGroup
            ...LeshenNavigationLink
          }
          topBarLinks {
            ...LeshenNavigationLink
          }
          callToActions {
            ... on ContentfulButton {
              ...LeshenButton
            }
            ... on ContentfulPhoneNumber {
              ...LeshenPhoneNumber
            }
          }
          logo {
            cloudinary {
              gatsbyImageData(placeholder: BLURRED)
              svg {
                code
                presentationWidth
              }
              context {
                custom {
                  alt
                  caption
                }
              }
            }
          }
        }
      }
    }
    allContentfulFooter(
      filter: { id: { eq: "aa62c71f-f99c-5fda-8630-097071f48046" } }
    ) {
      edges {
        node {
          __typename
          id
          identifier
          linkGroups {
            ...LeshenLinkGroup
          }
          logo {
            cloudinary {
              gatsbyImageData(placeholder: BLURRED)
              svg {
                code
                presentationWidth
              }
              context {
                custom {
                  alt
                  caption
                }
              }
            }
          }
          disclaimers {
            ...LeshenDisclaimer
          }
          navigationBackgroundColor
          navigationTextColor
          disclaimerBackgroundColor
          disclaimerTextColor
          logoLink
          logoBackgroundColor
          logoTextColor
          logoTopBorder
          logoBottomBorder
        }
      }
    }
    cities: allDatasetManagerFrontierinternetserviceAoa(
      filter: { State: { eq: $stateAbbreviation }, Area_Type: { eq: "city" } }
    ) {
      edges {
        node {
          City
          Page_Path
        }
      }
    }
    couchComputerImage: contentfulMedia(
      contentful_id: { eq: "6THVA088WFhKVVKbIkA3I2" }
    ) {
      cloudinary {
        gatsbyImageData(placeholder: BLURRED, width: 2560)
        context {
          custom {
            alt
            caption
          }
        }
      }
    }
    couchComputerImageMobile: contentfulMedia(
      contentful_id: { eq: "4xFuu4x9jo21kvzX7O56JS" }
    ) {
      cloudinary {
        gatsbyImageData(placeholder: BLURRED, width: 2560)
        context {
          custom {
            alt
            caption
          }
        }
      }
    }
    heroPrice: brandyPackage(brandy_id: { eq: "ftr-sigil-index-hero" }) {
      brand_token
      brandy_id
      name
      details
      bullets {
        text
        disclaimer {
          contentful_id
          symbol
          text
        }
      }
      price {
        currency
        amount
        sale
        prefix
        duration
        suffix
        before
        disclaimer {
          contentful_id
          symbol
          text
        }
      }
      comparison
      labelOffer
    }
    package1: brandyPackage(brandy_id: { eq: "ftr-sigil-5gig" }) {
      brand_token
      brandy_id
      name
      details
      bullets {
        text
        disclaimer {
          contentful_id
          symbol
          text
        }
      }
      price {
        currency
        amount
        sale
        prefix
        duration
        suffix
        before
        disclaimer {
          contentful_id
          symbol
          text
        }
      }
      comparison
      labelOffer
    }
    package2: brandyPackage(brandy_id: { eq: "ftr-sigil-2gig" }) {
      brand_token
      brandy_id
      name
      details
      bullets {
        text
        disclaimer {
          contentful_id
          symbol
          text
        }
      }
      price {
        currency
        amount
        sale
        prefix
        duration
        suffix
        before
        disclaimer {
          contentful_id
          symbol
          text
        }
      }
      comparison
      labelOffer
    }
    package3: brandyPackage(brandy_id: { eq: "ftr-sigil-1gig" }) {
      brand_token
      brandy_id
      name
      details
      bullets {
        text
        disclaimer {
          contentful_id
          symbol
          text
        }
      }
      price {
        currency
        amount
        sale
        prefix
        duration
        suffix
        before
        disclaimer {
          contentful_id
          symbol
          text
        }
      }
      comparison
      labelOffer
    }
    package4: brandyPackage(brandy_id: { eq: "ftr-sigil-500mbp" }) {
      brand_token
      brandy_id
      name
      details
      bullets {
        text
        disclaimer {
          contentful_id
          symbol
          text
        }
      }
      price {
        currency
        amount
        sale
        prefix
        duration
        suffix
        before
        disclaimer {
          contentful_id
          symbol
          text
        }
      }
      comparison
      labelOffer
    }
    disclaimerOne: brandyDisclaimer(brandy_id: { eq: "disclaimer-ftr-visa" }) {
      id
      text
      symbol
    }
    disclaimerTwo: brandyDisclaimer(brandy_id: { eq: "disclaimer-ftr-eeroo" }) {
      id
      text
      symbol
    }
    disclaimerThree: brandyDisclaimer(
      brandy_id: { eq: "disclaimer-ftr-archer-router" }
    ) {
      id
      text
      symbol
    }
    disclaimerFour: brandyDisclaimer(
      brandy_id: { eq: "disclaimer-digital-voice" }
    ) {
      id
      text
      symbol
    }
    disclaimerFive: brandyDisclaimer(
      brandy_id: { eq: "disclaimer-frt-frontiersecure" }
    ) {
      id
      text
      symbol
    }
    disclaimerSix: brandyDisclaimer(
      brandy_id: { eq: "disclaimer-ftr-fibermptp" }
    ) {
      id
      text
      symbol
    }
    disclaimerSeven: brandyDisclaimer(
      brandy_id: { eq: "disclaimer-ftr-wholehomewifi" }
    ) {
      id
      text
      symbol
    }
    disclaimerEight: brandyDisclaimer(
      brandy_id: { eq: "disclaimer-ftr-fiber" }
    ) {
      id
      text
      symbol
    }
  }
`
